const Banner = {
    "img":{
        "desktop":'https://www.portalinmobiliario.com/static_assets/home-assets/desktop_banner.png',
        "mobile": 'https://www.portalinmobiliario.com/static_assets/home-assets/mobile_banner.png',
    },
    "alt_img": "¿Necesitas asesoría para tu inversión?",
    "title": "La mejor asesoría en todo tu proceso",
    "url": "https://www.portalinmobiliario.com/h/blog/compra-directa",
    "text_url": "Quiero que me contacten",
};

module.exports = Banner;

